<template>
    <div class="body">
        <div class="middle">
            <div class="list_box" @click="newPage(item.id)" v-for="(item, index) of data.items" :key="index">
                <div class="m_img">
                    <img :src="item.cover" />
                </div>
                <div class="right2">
                    <div class="title2">
                        <template v-if="item.highlightFields.title">
                            <span v-html="item.highlightFields.title.join()"></span>
                        </template>
                        <template v-else>
                            {{ item.title }}
                        </template>
                    </div>
                    <div class="content2">
                        <template v-if="item.highlightFields.description">
                            <span v-html="item.highlightFields.description.join()"></span>
                        </template>
                        <template v-else>
                            {{ item.description }}
                        </template>
                    </div>
                    <div class="button">
                        <el-button size="mini" @click="newPage(item.id)">view+</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
    },
    methods: {
        newPage(id) {
            this.selectId = Number(id);
            this.$router.push({
                path: '/news',
                query: { id: this.selectId }
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.body {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.middle {

    .list_box {
        cursor: pointer;
        display: flex;
        padding-top: 40px;
        padding-bottom: 40px;
        overflow: hidden;
        border-bottom: 1px solid #ccc;

        // aspect-ratio: 6/1;
        .m_img img {
            width: 250px;
            aspect-ratio: 3/2;
            overflow: hidden;
            border-radius: 4%;
        }

        .right2 {
            padding-left: 10px;
            width: 100%;
            margin-left: 1vw;
            overflow: hidden;

            .title2 {
                font-size: 22px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .title2:hover {
                color: #497dcc;
            }

            .content2 {
                font-size: 15px;
                margin-top: 30px;
                color: #999;
            }

            .button {
                margin-top: 20px;
            }
        }

        .pubdate {
            border-left: 1px solid #ccc;
            margin-left: 1vw;
            padding-left: 2vw;
        }

    }

}

@media screen and (max-width: 1000px) {
    .middle {
        .list_box {
            width: 100%;

            .m_img img {
                width: 200px;
            }

            .right2 {
                .title2 {
                    font-size: 18px;
                }

                .content2 {
                    font-size: 15px;
                }
            }
        }
    }
}

/deep/ .el-button--default {
    background-color: #497ddc;
    border-radius: 6px;
    font-size: 15px;
    color: white;
    padding: 5px 18px;
    font-weight: 400;

    &:hover {
        background-color: #4774c9;
    }
}

@media screen and (max-width: 700px) {

    .middle {
        .list_box {
            width: 90%;
            display: block;
            padding: 20px 0;

            .m_img img {
                width: 100%;
                border-radius: 0;
            }

            .right2 {
                padding: 20px 5px;

                .title2 {
                    font-size: 22px;
                }

                .content2 {
                    font-size: 18px;
                }

                .button {
                    float: right;
                }
            }
        }
    }
}
</style>
